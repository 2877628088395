.playerContainer {
   // position: absolute;
   // top: 0;
   // bottom: 0;
   // right: 0;
   // left: 0;
   // height: 100%;
   // width: 100%;
   // background-color: black;
}
.iframe {
   background-color: black;
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   border-radius: 0;
}

.closeIcon {
   position: absolute;
   top: 17%;
   right: 5%;
   width: 40px;
   height: 40px;
   border-radius: 20px;
   background-color: white;
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 1000;
}
